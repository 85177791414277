import { useUserStore } from "~/stores/userStore";
import { useOrganizerStore } from "~/stores/organizerStore";

export const useStoreInit = () => {
  const orgaStore = useOrganizerStore();

  const init = async (foceInit = false) => {
    try {
      
      // USER
      const iUserToInit = foceInit || (!useUserStore().user || !useUserStore().user.id);
      if (iUserToInit) {
        const responseUser = await $fetch("/api/user?extendMeta=true");
        useUserStore().setUser(responseUser.data);
      }

      // ORGANIZER
      const iOrganizerToInit = foceInit || (!orgaStore.organizer || !orgaStore.organizer.orgaId);
      if (iOrganizerToInit) {
        const responseOrganizer = await $fetch(
          `/api/organizer?orgaId=${useUserStore().user.orgaId}`
        );
        orgaStore.setOrga(responseOrganizer.data);
      }

    } catch (error) {
      // Global error handling if something goes wrong
      console.error("Error during store initialization:", error);
      return navigateTo("/auth/login");
    }
  };

  return {
    init,
  };
};
